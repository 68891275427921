<template>
	<div class="flex flex-col h-full">		
		<div class="flex-auto overflow-scroll">			
			<h3 class="text-2xl font-bold pt-4 px-4 flex content-center">  
				<div @click="utils.goBack(router, '/admin/proveedores')" class="pr-2 flex items-center cursor-pointer">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
						<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
					</svg>
				</div>

				Editar proveedor
			</h3>

			<div class="block md:flex">
				<div class="w-full md:w-8/12 p-4">
					<div v-if="proveedor" class="w-full">
						<div class="w-full">
							<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
								Nombre
							</label>
							<input class="appearance-none border order-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500  w-full mb-2" id="nombre" type="text" placeholder="Nombre" v-model="proveedor.nombre">						
						</div>
						<div class="w-full">
							<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
								Correo
							</label>
							<input class="appearance-none border order-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500  w-full mb-2" id="nombre" type="text" placeholder="Correo" v-model="proveedor.correo">						
						</div>

						<div class="w-full">
							<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
								Pedido mínimo (B2B)
							</label>
							<input class="appearance-none border order-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500  w-full mb-2" id="nombre" type="number" placeholder="1" v-model="proveedor.minimo">		
						</div>		

						<div class="w-full">
							<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
								Shop name
							</label>
							<input class="appearance-none border order-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500  w-full mb-2" id="nombre" type="text" placeholder="Shop name" v-model="proveedor.shop_name">						
						</div>

						<div class="w-full">
							<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
								Mínimo para envío gratis (B2C)
							</label>
							<input class="appearance-none border order-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500  w-full mb-2" id="nombre" type="number" placeholder="1" v-model="proveedor.free_delivery_cutoff">		
						</div>		

						<div class="w-full">
							<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
								Color
							</label>
							<input class="appearance-none border order-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500  w-full mb-2" id="nombre" type="text" placeholder="Color" v-model="proveedor.color">						
						</div>

						<div class="mt-4 w-full">
							<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
								Descripción
							</label>
							<!-- <input class="appearance-none border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500 w-full mb-2" id="nombre" type="number" placeholder="0" v-model="producto.precio_publico">				 -->
							<textarea class="appearance-none focus:border-gray-500 border rounded w-full py-2 pb-0 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="Instrucciones" cols="40" rows="3" v-model="proveedor.texto"></textarea>
						</div>

						<div class="w-full">
							<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
								Tarimas
							</label>
							<input class="appearance-none border order-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500  w-full mb-2" id="nombre" type="number" placeholder="1" v-model="proveedor.tarimas">		
						</div>
						<div class="mb-4">
							<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
								Pricing
							</label>

							<select v-model="proveedor.pricing" id="" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
								<option selected disabled value="">Escoger un pricing</option>
								<option v-for="pricing in pricings" v-bind:key="pricing.id" :value="pricing">{{pricing.nombre}}</option>			
							</select>
						</div>

						<div v-if="proveedor.pricing" class="w-full text-left pt-0 mb-4">
							<div class="border boder-gray-300 rounded bg-white">
								<div class="overflow-scroll">
									<table class="w-full text-sm text-left ">
										<thead class="text-s border-b border-b-gray-200">														
											<th scope="col" class="py-3 px-6">
												Tarima
											</th>											
											<th scope="col" class="py-3 px-6">
												Envío base
											</th>					
											<th scope="col" class="py-3 px-6">
												Envío adicional
											</th>
											<!-- <th scope="col" class="py-3 px-6">
												Express (entre semana)
											</th>
											<th scope="col" class="py-3 px-6">
												Express (fin de semana)
											</th> -->
											<th scope="col" class="py-3 px-6">
												Tienda en línea
											</th>				
											<th scope="col" class="py-3 px-6">
												Comisión
											</th>						
										</thead>
										<tbody>
											<tr class="text-s">		
												<td class="py-3 px-6">							
													${{proveedor.pricing.almacen}}	
												</td>							
												<td class="py-3 px-6">							
													${{proveedor.pricing.envio_base}}	
												</td>
												<td class="py-3 px-6">							
													${{proveedor.pricing.envio_adicional}}	
												</td>						
												<!-- <td class="py-3 px-6">							
													${{proveedor.pricing.express_entre_semana}}
												</td>			
												<td class="py-3 px-6">							
													${{proveedor.pricing.express_fin_de_semana}}
												</td>	 -->
												<td class="py-3 px-6">							
													${{proveedor.pricing.ecom_base}}
												</td>									
												<td class="py-3 px-6">							
													{{proveedor.pricing.ecom_comision}}%
												</td>															
											</tr>              
										</tbody>
									</table>
								</div>
							</div>
						</div>

						<div class="w-full">
							<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
								Shop
							</label>
							<div class="border border-primary rounded flex flex-row bg-white  mb-2">
								<button @click="proveedor.ecom = false" :class="[{'bg-primary hover:bg-primary-dark text-white': proveedor.ecom == false, 'text-primary': proveedor.ecom == true}]" class="w-full font-bold py-2 px-4" >
									Desactivo
								</button>
								<button @click="proveedor.ecom = true" :class="[{'bg-primary hover:bg-primary-dark text-white': proveedor.ecom == true, 'text-primary': proveedor.ecom == false}]" class="w-full font-bold py-2 px-4" >
									Activo
								</button>	
							</div>
						</div>	

						<div class="w-full">
							<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
								Standalone
							</label>
							<div class="border border-primary rounded flex flex-row bg-white  mb-2">
								<button @click="proveedor.standalone = false" :class="[{'bg-primary hover:bg-primary-dark text-white': proveedor.standalone == false, 'text-primary': proveedor.standalone == true}]" class="w-full font-bold py-2 px-4" >
									No
								</button>
								<button @click="proveedor.standalone = true" :class="[{'bg-primary hover:bg-primary-dark text-white': proveedor.standalone == true, 'text-primary': proveedor.standalone == false}]" class="w-full font-bold py-2 px-4" >
									Si
								</button>	
							</div>
						</div>						
					</div>

				</div>
				<div class="w-full md:w-4/12 p-4">							
					<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
						Logo
					</label>

					<div v-if="proveedor.logo" class="rounded overflow-hidden w-1/2 mb-2 m-auto">
						<img :src="proveedor.logo" class="">
					</div>

					<div class="cursor-pointer text-center w-full bg-primary hover:bg-primary-dark rounded text-white font-bold mb-2">
						<label class="cursor-pointer inline-block w-full py-2 px-4" for="logo">{{proveedor.logo ? "Actualizar logo" : "Subir logo"}}</label>
						<input
						class="hidden"
						type="file"
						id="logo"
						accept="image/png, image/jpeg"
						@change="uploadFoto(proveedor, 'logo', $event)"
						/>
					</div>

					<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
						Foto
					</label>

					<div v-if="proveedor.foto" class="rounded overflow-hidden w-1/2 mb-2 m-auto">
						<img :src="proveedor.foto" class="">
					</div>

					<div class="cursor-pointer text-center w-full bg-primary hover:bg-primary-dark rounded text-white font-bold">
						<label class="cursor-pointer inline-block w-full py-2 px-4" for="foto">{{proveedor.foto ? "Actualizar foto" : "Subir foto"}}</label>
						<input
						class="hidden"
						type="file"
						id="foto"
						accept="image/png, image/jpeg"
						@change="uploadFoto(proveedor, 'foto', $event)"
						/>
					</div>
				</div>				
			</div>
		</div>

		<div class="p-4 flex-none border-t border-t-gray-300">
			<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="updateProveedor()">
				Actualizar proveedor
			</button>						
		</div>

	</div>
</template>

<script>
	import { useRoute, useRouter } from 'vue-router' 
	import { onMounted, ref } from "vue"
	import { useToast } from 'vue-toast-notification';
	import { useLoading } from 'vue-loading-overlay'
	import { utils } from './utils/utils.js'

	export default {
		name: 'Order',
		setup() {
			const route = useRoute()
			const router = useRouter()	
			const proveedor = ref({})		
			const pricings = ref([])		

			const loading = useLoading({
				color: "#428b74",
				loader: "spinner"
			});

			async function updateProveedor() {	
				let loader = loading.show()

				utils.updateProveedor(proveedor.value.id, { 
					nombre: proveedor.value.nombre, 
					correo: proveedor.value.correo, 
					shop_name: proveedor.value.shop_name,
					color: proveedor.value.color,
					almacen: proveedor.value.almacen,
					ecom: proveedor.value.ecom,
					texto: proveedor.value.texto,
					tarimas: proveedor.value.tarimas,
					foto: proveedor.value.foto,
					logo: proveedor.value.logo,
					pricing: proveedor.value.pricing.id,
					minimo: proveedor.value.minimo,
					free_delivery_cutoff: proveedor.value.free_delivery_cutoff,
					standalone: proveedor.value.standalone

				})	
				.then( () => {
					loader.hide()	
					useToast().success('Proveedor actualizado', { position: "top-right", duration: 5000 });
				})	
				.catch( () => {
					loader.hide()
					useToast().error('Hubo un error. Favor de intentarlo otra vez.', { position: "top-right", duration: 5000 });
				})		
			}

			const uploadFoto= async (pedido, tipo, evt) => {
				let loader = loading.show()

				uploadFile(evt)
				.then( fileName => {					
					return getURL(fileName)
				})
				.then( url => {					
					proveedor.value[tipo] = url
					useToast().success('Foto subido', { position: "top-right", duration: 5000 });
					loader.hide()
					updateProveedor()
				})	
				.catch( error => {
					console.log(error)
					loader.hide()
					alert("Hubo un error, favor de intentarlo otra vez.")
				})			
			}

			async function uploadFile(evt) {									
				if (!evt.target.files || evt.target.files.length === 0) {
					throw new Error("Selecciona un archivo")
				}
				const file = evt.target.files[0]
				const fileExt = file.name.split(".").pop()
				const fileName = `${Math.random()}.${fileExt}`
				const filePath = `${fileName}`					

				return await utils.uploadFile('misc', filePath, file)						
			}

			async function getURL(file) {	
				console.log(file)
				let fileOnly = file.split('misc/')[1]
				return await utils.getURL('misc', fileOnly)				
			}	

			onMounted( async () => {					
				utils.getProveedor(route.params.proveedor_id)
				.then( data => {
					proveedor.value = data
				})

				utils.getPricings()
				.then( data => {
					pricings.value = data
				})

				
			})
	
			return {   				
				route,
				router,
				updateProveedor,
				proveedor,				
				uploadFoto,
				pricings,
				utils
			}
		},
	}
</script> 