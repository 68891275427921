<template>
	<div class="flex flex-col h-full">
		<div class="block h-full overflow-y-scroll">
			<div class="flex-auto overflow-scroll w-full"> 
				<div class="flex justify-between pt-4 px-4">
					<div class="flex content-center items-center">
						<div @click="utils.goBack(router, `/proveedor/${route.params.proveedor_id}/clientes`)" class="pr-2 flex items-center cursor-pointer">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
							</svg>
						</div>	
						<h3 class="text-2xl font-bold">
							Cliente
						</h3>
					</div>

					<div class="text-left flex-none">
						<button class="rounded bg-primary hover:bg-primary-dark text-white font-bold p-1 px-4"  @click="copyLink(clienteProveedor.cliente)">
							Copiar enlace
						</button>
					</div>
				</div>				

				<div v-if="clienteProveedor.cliente" class="w-full text-left px-4 mt-4">
					<label class="block text-gray-700 text-sm font-bold">
						Información
					</label>

					<div class="border boder-gray-300 rounded mt-2 bg-white">
						<div class="p-4">
							<p class="font-medium">Nombre</p>
							<p>{{clienteProveedor.cliente.nombre}}</p>

							<p class="font-medium mt-2">Contact</p>
							<p>{{clienteProveedor.cliente.contacto_nombre}}</p>
							<p>{{clienteProveedor.cliente.contacto_correo}}</p>
							<p>{{clienteProveedor.cliente.contacto_telefono}}</p>

							<p class="font-medium mt-2">Dirección</p>						
							<p>{{clienteProveedor.cliente.direccion_calle}} {{clienteProveedor.cliente.direccion_exterior}}</p>
							<p>{{clienteProveedor.cliente.direccion_interior}}</p>
							<p>{{clienteProveedor.cliente.direccion_colonia}} {{clienteProveedor.cliente.direccion_codigo}}</p>						
							<p>{{clienteProveedor.cliente.direccion_estado}}</p>
						</div>

						<div class="px-4 pb-4">
							<router-link :to=' "/proveedor/" + proveedor.id + "/cliente/" + clienteProveedor.cliente.id + "/editar" '>
								<button class="w-full rounded mr-4 border border-primary hover:border-primary-dark hover:text-primary-dark text-primary font-bold p-2 px-4">
									Editar
								</button>
							</router-link>
						</div>
					</div>
				</div>	

				<div class="w-full text-left mt-4 px-4">
					<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
						Estado
					</label>
					<div class="border border-primary rounded flex items-center flex flex-row flex-grow  bg-white">
						
						<div @click="clienteProveedor.activo = false; updateClienteProveedor(clienteProveedor)" :class="[{'bg-primary hover:bg-primary-dark text-white': clienteProveedor.activo === false, 'text-primary': clienteProveedor.activo === true}]" class="cursor-pointer truncate text-center w-2/4 font-bold py-2 px-4" >
							Desactivado
						</div>	
						<button @click="clienteProveedor.activo = true; updateClienteProveedor(clienteProveedor)" :class="[{'bg-primary hover:bg-primary-dark text-white': clienteProveedor.activo === true, 'text-primary': clienteProveedor.activo === false}]" class="whitespace-nowrap w-2/4 font-bold py-2 px-4" >
							Activo
						</button>
					</div>										
				</div>

				<div class="w-full text-left mt-4 px-4" v-if="!proveedor.standalone">
					<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
						Aprobación
					</label>
					<div class="flex items-center mt-2">
						<div class="border border-primary rounded flex flex-row flex-grow bg-white">							
							<div @click="clienteProveedor.require_aprobacion = false; updateClienteProveedor(clienteProveedor)" :class="[{'bg-primary hover:bg-primary-dark text-white': clienteProveedor.require_aprobacion === false, 'text-primary': clienteProveedor.require_aprobacion === true}]" class="cursor-pointer truncate text-center w-2/4 font-bold py-2 px-4" >
								Desactivado
							</div>	
							<button @click="clienteProveedor.require_aprobacion = true; updateClienteProveedor(clienteProveedor)" :class="[{'bg-primary hover:bg-primary-dark text-white': clienteProveedor.require_aprobacion === true, 'text-primary': clienteProveedor.require_aprobacion === false}]" class="whitespace-nowrap w-2/4 font-bold py-2 px-4" >
								Activo
							</button>
						</div>										
					</div>
				</div>

				<div class="w-full text-left px-4 mt-4 bg">		
					<label class="block text-sm">
						<p class="font-bold text-gray-700">Precios especiales</p>
						<p class="font-regular text-gray-500">En caso de que este cliente tiene un precio especial para un producto. Puedes crear precios especiales en la pagina del producto.</p>
					</label>					
					<div class="flex justify-between mt-2 ">
						<select v-model="productoPrecio" class="w-7/12 col-span-3 border border-gray-300 focus:border-gray-500 border rounded py-2 px-3 text-gray-700leading-tight focus:outline-none focus:shadow-outline whitespace-nowrap">												
							<option selected disabled value="">Escoger un precio</option>
							<option v-for="productoPrecio in filteredProductosPrecios" v-bind:key="productoPrecio.id" :value="productoPrecio">{{productoPrecio.producto.nombre}}  {{productoPrecio.nombre}}  ${{productoPrecio.precio}}</option>					
						</select>
						<div class="w-5/12 text-left pl-4 flex-none">
							<button class="w-full rounded bg-primary hover:bg-primary-dark text-white font-bold p-2 px-4"  @click="createClienteProductoPrecio(productoPrecio)">
								Agregar precio
							</button>
						</div>
					</div>

					<div v-if="clienteProductoPrecios && clienteProductoPrecios.length > 0" class="border border-b-0 rounded mt-2  bg-white ">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b">						
								<th scope="col" class="py-3 px-6 w-3/12">
									Producto
								</th>
								<th scope="col" class="py-3 px-6 w-3/12">
									Tipo
								</th>						
								<th scope="col" class="py-3 px-6 w-3/12">
									Precio
								</th>	
								<th scope="col" class="py-3 px-6 w-3/12">

								</th>						
							</thead>
							<tbody v-for="clienteProductoPrecio in clienteProductoPrecios" v-bind:key="clienteProductoPrecio.id" class="text-s border-b">						
								<th scope="row" class="py-3 px-6 font-medium whitespace-nowrap ">
									{{clienteProductoPrecio.producto_precio.producto.nombre}}
								</th>
								<td class="py-3 px-6 whitespace-nowrap">
									{{clienteProductoPrecio.producto_precio.nombre}}
								</td>						
								<td class="py-3 px-6 whitespace-nowrap">
									{{clienteProductoPrecio.producto_precio.precio}}

								</td> 
								<td>
									<p class="cursor-pointer underline" @click="deleteClienteProductoPrecio(clienteProductoPrecio)">Eliminar</p>
								</td>
							</tbody>
						</table>
					</div>
				</div>

				<div class="w-full text-left px-4 mt-4">		
					<label class="block text-sm">
						<p class="font-bold text-gray-700">Productos especiales</p>
						<p class="font-regular text-gray-500">En caso de que este cliente puede pedir un producto desactivado.</p>
					</label>				
					<div class="flex justify-between mt-2">
						<select v-model="producto" class="w-7/12 col-span-3 border border-gray-300 focus:border-gray-500 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline whitespace-nowrap">												
							<option selected disabled value="">Escoger un producto</option>
							<option v-for="producto in filteredClienteProductos" v-bind:key="producto.id" :value="producto">{{producto.nombre}}</option>					
						</select>
						<div class="w-5/12 text-left pl-4 flex-none">
							<button class="w-full rounded bg-primary hover:bg-primary-dark text-white font-bold p-2 px-4"  @click="createClienteProducto(producto)">
								Agregar producto
							</button>
						</div>
					</div>

					<div v-if="clienteProductos && clienteProductos.length > 0" class="border boder-gray-300 border-b-0 rounded mt-2 bg-white">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b">						
								<th scope="col" class="py-3 px-6 w-9/12">
									Producto
								</th>

								<th scope="col" class="py-3 px-6 w-3/12">

								</th>						
							</thead>
							<tbody v-for="clienteProducto in clienteProductos" v-bind:key="clienteProducto.id" class="text-s border-b">						
								<th scope="row" class="py-3 px-6 font-medium whitespace-nowrap ">
									{{clienteProducto.producto.nombre}}
								</th>							
								<td>
									<p class="cursor-pointer underline" @click="deleteClienteProducto(clienteProducto)">Eliminar</p>
								</td>
							</tbody>
						</table>
					</div>
				</div>

				<div v-if="pedidos && pedidos.length == 0" class="mt-4 pb-4" >
					<label class="block text-gray-700 text-sm font-bold px-4">
						Ordenes
					</label>
					<empty class="mb-4" :texto=" 'El cliente no tiene pedidos' "> </empty>
				</div>

				<div v-if="pedidos && pedidos.length > 0" class="w-full text-left mt-4 pb-0 sm:pb-4">		
					<label class="block text-gray-700 text-sm font-bold px-4 mt-4">
						Ordenes
					</label>

					<div class=""> 
						<inbox class="mt-2" :user="'proveedor'" :ordenes="pedidos" :fields="{cliente: true, surtir: (proveedor.standalone ? false : true), aprobar: (proveedor.standalone ? false : true), facturar: true, entregar: true}"></inbox>
					</div>
				</div>	
			</div>					
		</div>

		<div v-if="clienteProveedor.cliente" class="p-4 flex-none border-t border-t-gray-300">
			<router-link :to=' "/proveedor/" + (proveedor ? proveedor.id : "") + "/orden?cliente=" + clienteProveedor.cliente.id '>
				<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4">
					Agregar orden
				</button>
			</router-link>
		</div>
	</div>
</template>

<script>
	import { supabase } from "../supabase"
	import { useRoute, useRouter } from 'vue-router'
	import { onMounted, ref, computed } from "vue"	
	import { useLoading } from 'vue-loading-overlay'
	import { useToast } from 'vue-toast-notification';
	import { utils } from "./utils/utils.js"

	export default {
		name: 'Order',
		setup() {
			const route = useRoute()						  
			const router = useRouter()						  
			const pedidos = ref(null)
			const producto = ref("")
			const productos = ref(null)
			const clienteProducto = ref(null)
			const clienteProductos = ref(null)
			const clienteProveedor = ref({})
			const proveedor = ref({})
			const clienteProductoPrecios = ref(null)
			const productoPrecios = ref(null)
			const productoPrecio = ref("")

			const loading = useLoading({
				color: "#428b74",
				loader: "spinner"
			});

			async function getProveedor() {
				let { data, error } = await supabase
				.from("proveedores")
				.select('id, nombre, funciones, standalone')          
				.eq("id", route.params.proveedor_id)
				.single()

				if (data) {   					
					return data
				} else if (error) {  
					console.log(error)
				}   

				return
			}

			async function getClienteProveedor() {
				let { data, error } = await supabase
				.from("cliente_proveedor")
				.select('id, activo, require_aprobacion, cliente(*), proveedor')          
				.eq("cliente", route.params.cliente_id)
				.eq("proveedor", route.params.proveedor_id)
				.single()

				if (data) {             					
					return data
				} else if (error) { 					
					console.log(error)
				}   
			}    

			async function updateClienteProveedor(cP) {		
				let loader = loading.show()	

				const { error } = await supabase
				.from('cliente_proveedor')
				.update({ 					
					activo: cP.activo,	
					require_aprobacion: cP.require_aprobacion				
				})
				.match({ id: cP.id })

				loader.hide()

				if (error) {					
					console.log(error)
				} else {
					useToast().success('Cliente actualizado', { position: "top-right", duration: 5000 });					
				}
			}  	

			async function createClienteProductoPrecio(producto_precio) {
				if (!producto_precio) {
					return useToast().warning(`Debes de escoger un producto.`, { position: "top-right" });				
				}

				let { data, error } = await supabase
				.from('cliente_producto_precio')	
				.insert([{
					"producto_precio": producto_precio.id,
					"cliente": route.params.cliente_id
				}])	
				.select('id, producto_precio( id, producto(id, nombre), nombre, precio)')	
				.single()		

				if (data) {
					productoPrecio.value = ""
					if (clienteProductoPrecios.value.length > 0) {
						clienteProductoPrecios.value.push(data)
					} else {
						clienteProductoPrecios.value = [data]
					}
				} else if (error) {  	
					console.log(error)
					useToast().warning(`Hubo un error, favor de intentarlo otra vez.`, { position: "top-right" });				
					throw error     
				}     
			}

			async function deleteClienteProductoPrecio(clienteProductoPrecio) {								
				const { data, error } = await supabase
				.from('cliente_producto_precio')
				.delete()
				.match({ id: clienteProductoPrecio.id })		

				if (data) {              					
					clienteProductoPrecios.value = clienteProductoPrecios.value.filter( cPP => cPP.id !== clienteProductoPrecio.id )					
				} else if (error) { 
					console.log(error) 
					alert(error)     
				}   	
			}

			async function getProductoPrecios() {				
				let { data, error } = await supabase
				.from("producto_precio")
				.select('id, nombre, producto!inner(id, proveedor, nombre), precio)')
				.match({
					"producto.proveedor": route.params.proveedor_id,
				})				

				if (data) {										
					return data
				} else if (error) {					
					console.log(error)
					alert('Hubo un error')		
				}				
			}

			async function getClienteProductoPrecios() {				
				let { data, error } = await supabase
				.from("cliente_producto_precio")
				.select('id, producto_precio(id, producto(id, nombre, proveedor), nombre, precio)')
				.match({
					"cliente": route.params.cliente_id,
				})					

				if (data) {											
					return data.filter( cPP => cPP.producto_precio.producto.proveedor == route.params.proveedor_id)
				} else if (error) {					
					console.log(error)
					alert('Hubo un error')		
				}				
			}						

			async function getPedidos() {
				let { data, error } = await supabase
				.from("pedidos")
				.select('id, standalone, envios(*), cancelado, require_aprobacion, require_entrega, require_factura, entregado, envio, proveedor (id, standalone), pagado, require_aprobacion, aprobado, cliente(nombre), created_at, surtido, remision_firmada, pedido_factura(*), line_items!inner(cantidad, cantidad_entregada, precio, producto (id, nombre, precio, proveedor (nombre)))')       
				.order('created_at', { ascending: false })
				.eq("cliente", route.params.cliente_id)
				.eq("proveedor", route.params.proveedor_id)

				if (data) {              
					return data
				} else if (error) { 
					console.log(error)
				}   
			}    

			function copyLink(cliente) {			
				navigator.clipboard.writeText(`${window.location.host}/cliente/${cliente.id}`);
				useToast().success('Enlace copiado', { position: "top-right" });
			}

			onMounted( async () => {
				getClienteProveedor()
				.then( data => {
					clienteProveedor.value = data
				})

				getProveedor().then( data => {
					proveedor.value = data
				})
				
				getPedidos().then( data => {
					pedidos.value = data
				})
				
				getProductoPrecios().then( data => {
					productoPrecios.value = data
				})
				
				getClienteProductoPrecios().then( data => {
					clienteProductoPrecios.value = data
				})

				utils.getProductosPorProveedor(route.params.proveedor_id)
				.then( data => {
					productos.value = data.filter( p => p.activo == false)
				}) 								
				
				utils.getClienteProductosPorProveedor(route.params.proveedor_id, route.params.cliente_id).then( data => {
					clienteProductos.value = data
				})

				// const res = await axios.get(`/api/get_delivery_cost?cliente_id=${route.params.cliente_id}`)							
			})

			const filteredClienteProductos = computed( () => {
				if (!clienteProductos.value || !productos.value) {
					return []
				}				
				
				return productos.value.filter( p => clienteProductos.value.find( cP => cP.producto.id == p.id ) == undefined)
			})

			const filteredProductosPrecios = computed( () => {
				if (!productoPrecios.value || !clienteProductoPrecios.value) {
					return []
				}
				
				let removePreciosAlreadyAdded = productoPrecios.value.filter( pP => clienteProductoPrecios.value.find( cPP => cPP.producto_precio.id == pP.id ) == undefined)

				let productoIds = clienteProductoPrecios.value.map( cPP => cPP.producto_precio.producto.id )				
				
				let removeProductsWithPrecios = removePreciosAlreadyAdded.filter( pP =>  productoIds.find( p => p == pP.producto.id ) == undefined ) 

				return removeProductsWithPrecios
			})

			function createClienteProducto(productoParaCrear) {
				utils.createClienteProducto(route.params.cliente_id, productoParaCrear.id)
				.then( data => {					
					producto.value = ""
					clienteProductos.value.push(data)
				})
			} 

			function deleteClienteProducto(clienteProducto) {
				utils.deleteClienteProducto(clienteProducto)
				.then( () => {
					clienteProductos.value = clienteProductos.value.filter( cP => cP.id !== clienteProducto.id )					
				})
			}				

			return {      				
				pedidos,
				clienteProveedor,
				proveedor,				
				updateClienteProveedor,
				copyLink,
				productoPrecios,
				clienteProductoPrecios,
				deleteClienteProductoPrecio,
				createClienteProductoPrecio,
				productoPrecio,
				filteredProductosPrecios,
				productos,
				producto,
				clienteProductos,
				clienteProducto,
				filteredClienteProductos,
				createClienteProducto,
				deleteClienteProducto,
				route,		
				router,		
				utils
			}
		},
	}
</script> 