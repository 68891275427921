<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">			
			<div class="flex justify-between items-center content-center pt-4">
				<h3 class="text-2xl font-bold px-4">      		
					Ordenes
				</h3>

				<div class="text-left flex-none pr-4">
					<button class="rounded bg-primary hover:bg-primary-dark text-white font-bold p-1 px-4"  @click="generateCSV()">
						Descargar
					</button>
				</div>
			</div>

			<div class="w-full text-left px-4 pb-2 pt-4">
				<select @change="updateQuery()" v-model="cliente" v-if="clientes.length > 0" id="countries" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
					<option selected value="">Todos los clientes</option>
					<option v-for="cliente in clientes" v-bind:key="cliente.id" :value="cliente">{{cliente.nombre}}</option>			
				</select>
			</div>

			<empty v-if="pedidos && filteredPedidos && filteredPedidos.length == 0" :texto=" cliente ? `${cliente.nombre} no tiene ordenes de compra` :'Aún no tienes ordenes de compra' "> </empty>

			<div v-if="filteredPedidos && filteredPedidos.length > 0" class="pb-4"> 
				<inbox class="mt-4" :user="'proveedor'" :ordenes="filteredPedidos" :fields="{fuente: proveedor.ecom, cliente: true, surtir: (proveedor.standalone ? false : true), pago: (proveedor.payments ? true : false), aprobar: (proveedor.standalone ? false : true), facturar: true, entregar: true}"></inbox>
			</div>	
		</div>

		<div class="p-4 flex-none border-t border-t-gray-300">
			<router-link :to="`/proveedor/${proveedor.id}/orden${ cliente.id ? `?cliente=${cliente.id}` : ''}`">
				<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4">
					Agregar orden
				</button>
			</router-link>
		</div>
	</div>
</template>

<script>
	import { supabase } from "../supabase"
	import { useRoute, useRouter } from 'vue-router'
	import { onMounted, ref, computed } from "vue"	
	import { utils } from "./utils/utils.js"
	import { useLoading } from 'vue-loading-overlay'

	const loading = useLoading({
		color: "#428b74",
		loader: "spinner"
	});

	export default {
		name: 'Order',
		setup() {
			const route = useRoute()
			const router = useRouter()
			const proveedor = ref({})
			const pedidos = ref(null)
			const clientes = ref([])
			const cliente = ref("")		

			async function getClientes() {
				let { data, error, status } = await supabase
				.from("cliente_proveedor")
				.select('proveedor, cliente(id, nombre, contacto_correo)')				
				.match({										
					"proveedor": route.params.proveedor_id
				})

				if (data) {   					
					clientes.value = data.sort((a, b) => {
						let fa = a.cliente.nombre.toLowerCase(),
						fb = b.cliente.nombre.toLowerCase();

						if (fa < fb) {
							return -1;
						}
						if (fa > fb) {
							return 1;
						}
						return 0;
					}).map( d => { return d.cliente})
				} else if (error) {  
					if (status == 406) {
						return						
					} else {
						console.log(error)
						alert('Hubo un error')
					}
				}   

				return
			}

			const filteredPedidos = computed( () => {
				return pedidos.value ? pedidos.value.filter( p => cliente.value.id ? p.cliente.id == cliente.value.id : true ) : []
			})

			function generateCSV() {
				if (filteredPedidos.value.length == 0) {
					return
				}

				let csv = "Fecha, Pedido, Cancelado, Surtido, Entregado, Cliente, Total" 

				filteredPedidos.value.forEach( pedido => {			
					csv += `\n${(new Date(pedido.created_at)).getMonth()+1}/${(new Date(pedido.created_at)).getDate()}/${(new Date(pedido.created_at)).getFullYear()},${pedido.id},${pedido.cancelado},${pedido.surtido},${pedido.entregado || "false"},${pedido.cliente ? pedido.cliente.nombre.replaceAll(",","") : ""},${pedido.line_items.map(li => (li.cantidad ? li.precio * li.cantidad : 0)).reduce((a, b) => a + b)}`
				})

				//cantidad_entregada need to consider in the future, but there is an issue with orders that don't require delivery not having cantidad entregada ...

				const anchor = document.createElement('a');
				anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
				anchor.target = '_blank';
				anchor.download = 'ordenes.csv';
				anchor.click();
			}


			function generateCSVWithLineItems() {
				let csv = "Fecha, Pedido, Cancelado, Surtido, Entregado, Proveedor, Cliente, Producto, Cantidad pedida, Cantidad entregada" 

				pedidos.value.forEach( pedido => {
					console.log(pedido)
					pedido.line_items.forEach( lineItem => {
						csv += `\n${(new Date(pedido.created_at)).toLocaleDateString("en-GB", { timeZone: 'America/Mexico_City' })},${pedido.id},${pedido.cancelado},${pedido.surtido}, ${pedido.entregado || "false"},${pedido.proveedor ? pedido.proveedor.nombre.replaceAll(",","") : ""},${pedido.cliente ? pedido.cliente.nombre.replaceAll(",","") : ""},${lineItem.producto.nombre.replaceAll(",","")},${lineItem.cantidad}, ${lineItem.cantidad_entregada || ""}`
					})
				})

				const anchor = document.createElement('a');
				anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
				anchor.target = '_blank';
				anchor.download = 'ordenes.csv';
				anchor.click();
			}

			function updateQuery() {
				if (cliente.value.id) {
					let data = { 
						path: `/proveedor/${route.params.proveedor_id}/ordenes`, 
						query: { cliente: cliente.value.id }
					}

					router.push(data)
				} else {
					let data = {
						path: `/proveedor/${route.params.proveedor_id}/ordenes`
					}
					router.push(data)				
				}
			}

			onMounted( async () => {
				let loader = loading.show()
				
				utils.getProveedor(route.params.proveedor_id)
				.then( data => {					
					proveedor.value = data
				})

				utils.getPedidosPorProveedor(route.params.proveedor_id)
				.then( data => {
					loader.hide()
					pedidos.value = data					
				})

				getClientes()
				.then( () => {
					if (route.query.cliente) {
						cliente.value = clientes.value.find( c => c.id == route.query.cliente)
					}
				})
			})

			return {
				proveedor,
				pedidos,
				clientes,
				cliente,
				filteredPedidos,
				generateCSV,
				generateCSVWithLineItems,
				updateQuery
			}
		},
	}
</script>
