<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">
			<h3 class="text-2xl font-bold pt-4 px-4">      		
				Recibos
			</h3>	

			<empty v-if="recibos && recibos.length == 0" :texto="'Aún no tienes un recibo.'"> </empty>

			<div v-if="recibos && recibos.length > 0" class=" w-full text-left p-4 ">
				<div class="border boder-gray-300 rounded bg-white x2">
					<div class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-b-gray-200">						
								<th scope="col" class="py-3 px-6">
									Periodo
								</th>
								<th v-if="admin" scope="col" class="py-3 px-6">
									Proveedor
								</th>
								<th v-if="recibos.filter( r => r.ecom).length > 0" scope="col" class="py-3 px-6">
									Tienda en línea
								</th>	
								<th scope="col" class="py-3 px-6">
									Almacén
								</th>					
								<th scope="col" class="py-3 px-6">
									Envíos
								</th>								
								<th scope="col" class="py-3 px-6">
									Total
								</th>
								<th scope="col" class="py-3 px-6">
									Pago
								</th>	
								<th v-if="admin" scope="col" class="py-3 px-6">
									Factura
								</th>					
							</thead>
							<tbody>
								<tr class="text-s border-b" v-for="recibo in recibos" v-bind:key="recibo.id" >
									<td class="py-3 px-6 underline">								
										<router-link :to="getLink(recibo)">
											{{recibo.mes}}/{{recibo.año}}
										</router-link> 										
									</td>

									<td v-if="recibos.filter( r => r.ecom).length > 0" class="py-3 px-6">							
										${{recibo.ecom || 0}}
									</td>
									

									<td v-if="admin" class="py-3 px-6">							
										{{recibo.proveedor.nombre}}
									</td>
									<td class="py-3 px-6 ">							
										${{Math.round(recibo.almacen * 100)/100}}
									</td>
									<td class="py-3 px-6 ">							
										${{Math.round(recibo.envios * 100)/100}}
									</td>										
									<td class="py-3 px-6 ">							
										${{Math.round((recibo.envios + recibo.almacen) * 1.16 * 100)/100}}
									</td>
									<td class="py-3 px-6">							
										{{recibo.pago ? "Pagado" : "Pendiente"}}
									</td>	
									<td v-if="admin" class="py-3 px-6">							
										{{recibo.factura ? "Facturado" : "Pendiente"}}
									</td>														
								</tr>              
							</tbody>
							<tfoot class="font-bold">
								<tr>
									<td class="py-3 px-6">								
										Total
									</td>
									<td v-if="admin" class="py-3 px-6">							
										
									</td>
									<td class="py-3 px-6 ">							
										
									</td>
									<td class="py-3 px-6 ">							
										
									</td>										
									<td class="py-3 px-6 ">										
										${{Math.round((recibos.filter( r => !r.pago ).map( r => r.envios + r.almacen)).reduce((a, b) => a + b) * 1.16 * 100)/100}}
									</td>
									<td class="py-3 px-6">							
										
									</td>	
									<td v-if="admin" class="py-3 px-6">							
										
									</td>												
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div v-if="admin" class="p-4 flex-none border-t border-t-gray-300">							
			<router-link :to="'/admin/corte'">
				<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4">
					Hacer corte
				</button>
			</router-link>
		</div>
	</div>
</template>

<script>
	import { useRoute } from 'vue-router'	
	import { onMounted, ref } from "vue"
	import { utils } from './utils/utils.js'

	export default {		
		setup() {
			const route = useRoute()
			const recibos = ref([])
			const admin = ref(false)

			onMounted( async () => {
				if (route.name == "Admin_Recibos") {
					admin.value = true
					recibos.value = await utils.getAllRecibos()
					recibos.value = recibos.value.filter( r => (r.envios != 0 ||r.almacen != 0) && r.proveedor.id != 21)
				} else {
					recibos.value = await utils.getRecibos(route.params.proveedor_id)					
				}					
			})	

			function getLink(recibo) {
				if (route.name == "Admin_Recibos") {
					return `/admin/recibo/${recibo.id}`
				}

				return `/proveedor/${route.params.proveedor_id}/recibo/${recibo.id}`
			}

			return {
				route,
				recibos,
				getLink,
				admin
			}
		}
	}
</script>