<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">
			<div class="pt-4 px-4 mb-4">
				<div class="flex content-center">
					<div @click="utils.goBack(router, admin ? '/admin/recibos' : `/proveedor/${route.params.proveedor_id}/recibos`)" class="pr-2 flex items-center cursor-pointer"> 				
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
						</svg>
					</div>	
					<h3 v-if="recibo" class="text-2xl font-bold">
						Recibo {{recibo.mes}}/{{recibo.año}}
					</h3>
				</div>	
			</div>

			<div v-if="admin && !recibo.pago" class="mb-4 px-4">
				<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="marcarPagado()">
					Marcar pagado
				</button>
			</div>

			<div class="mb-4 px-4">
				<div v-if="admin && !recibo.factura" class="mt-4">
					<label class="whitespace-nowrap cursor-pointer inline-block w-full border border-primary hover:bg-primary-dark text-white bg-primary font-bold text-center px-4 py-2 rounded" :for="1">Subir factura</label>
					<input
					class="hidden"
					type="file"
					:id="1"
					accept="application/pdf, .zip, .xml"
					@change="uploadFactura($event)"		
					/>
				</div>
			</div>

			<div v-if="recibo" class="w-full text-left p-4 pt-0">
				<div class="border boder-gray-300 rounded bg-white">
					<div class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-b-gray-200">														
								<th scope="col" class="py-3 px-6">
									Almacén
								</th>	
								<th v-if="recibo.ecom" scope="col" class="py-3 px-6">
									Tienda en línea
								</th>				
								<th scope="col" class="py-3 px-6">
									Envíos
								</th>				
								<th scope="col" class="py-3 px-6">
									Extras
								</th>					
								<th scope="col" class="py-3 px-6">
									Total (c/IVA)
								</th>
								<th scope="col" class="py-3 px-6">
									Pago
								</th>
								<th scope="col" class="py-3 px-6">
									Factura
								</th>						
							</thead>
							<tbody>
								<tr class="text-s">		
									<td v-if="recibo.ecom" class="py-3 px-6">							
										${{recibo.ecom}}	
									</td>							
									<td class="py-3 px-6">							
										${{Math.round(recibo.almacen * 100)/100}}
									</td>
									<td class="py-3 px-6">							
										${{Math.round(recibo.envios * 100)/100}}
									</td>						
									<td class="py-3 px-6">							
										${{Math.round(recibo.extras * 100)/100}}
									</td>			
									<td class="py-3 px-6">							
										${{Math.round((recibo.envios + recibo.almacen) * 1.16 * 100)/100}}
									</td>	
									<td class="py-3 px-6">							
										{{recibo.pago ? "Pagado" : "Pendiente"}}
									</td>
									<td v-if="recibo.factura" class="py-3 px-6">							
										<a :href="recibo.factura" target="_blank">
											Descargar
										</a>
									</td>	
									<td v-if="!recibo.factura" class="py-3 px-6">							
										Pendiente
									</td>															
								</tr>              
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<label class="block text-gray-700 text-sm font-bold pl-4">
				Envíos
			</label>

			<empty v-if="envios && envios.length == 0" :texto="'No hubo entregas'"> </empty>

			<div v-if="envios && envios.length > 0" class="w-full text-left p-4 ">
				<div class="border boder-gray-300 rounded bg-white overflow-hidden">
					<div class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-b-gray-200">						
								<th scope="col" class="py-3 px-6">
									Pedido
								</th>
								<th scope="col" class="py-3 px-6">
									Fecha
								</th>	
								<th scope="col" class="py-3 px-6">
									Distancia
								</th>					
								<th scope="col" class="py-3 px-6">
									Costo
								</th>				
								<th scope="col" class="py-3 px-6">
									Extra
								</th>										
							</thead>
							<tbody>
								<tr class="text-s border-b" v-for="envio in envios" v-bind:key="envio.id">
									<td class="py-3 px-6 underline">								
										<router-link :to="`/proveedor/${route.params.proveedor_id}/orden/${envio.pedido.id}`">
											{{envio.pedido.id}}
										</router-link> 										
									</td>
									<td class="py-3 px-6">							
										{{envio.created_at ? (new Date(envio.created_at)).toLocaleDateString("en-GB", { timeZone: 'America/Mexico_City' }) : ""}}
									</td>
									<td class="py-3 px-6">							
										{{Math.ceil(envio.distancia/10)/100}} km
									</td>						
									<td class="py-3 px-6">
										${{Math.round( (envio.costo) * 100) / 100}}
									</td>
									<td class="py-3 px-6">
										${{Math.round( (envio.cargo_extra || 0) * 100) / 100}}
									</td>
								</tr>              
							</tbody>
							<tfoot class="">
								<tr>
									<th scope="col" class="py-3 px-6">
										Total
									</th>
									<th scope="col" class="py-3 px-6">
										{{envios.length}}
									</th>	
									<th scope="col" class="py-3 px-6">
										{{Math.round((envios.map( e => {return (e.distancia)} ).reduce((a, b) => a + b)/1000) * 100) / 100 }} km
									</th>					
									<th scope="col" class="py-3 px-6">
										${{Math.round(envios.map( e => {return e.costo} ).reduce((a, b) => a + b) * 100) / 100 }}
									</th>
									<th scope="col" class="py-3 px-6">
										${{Math.round(envios.map( e => {return (e.cargo_extra || 0)} ).reduce((a, b) => a + b) * 100) / 100 }}
									</th>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { useRoute, useRouter } from 'vue-router'
	import { supabase } from "../supabase"
	import { onMounted, ref } from "vue"
	import { utils } from './utils/utils.js'
	import axios from "axios"
	import { useLoading } from 'vue-loading-overlay'

	export default {		
		setup() {
			const route = useRoute()
			const router = useRouter()
			const envios = ref(null)	
			const paymentMethods = ref([])		
			const paymentMethod = ref("")
			const recibo = ref(null)
			const admin = ref(false)

			const loading = useLoading({
				color: "#428b74",
				loader: "spinner"
			});

			async function getEnvios() {
				let { data, error } = await supabase
				.from("envios")
				.select('id, distancia, costo, cargo_extra, created_at, pedido(id, cliente(nombre))')       
				.order('created_at', { ascending: true })
				.eq("recibo", route.params.recibo_id)				

				if (data) {              
					return data
				} else if (error) { 
					console.log("getEnvios", error)       
					throw error 
				}   
			} 

			onMounted( async () => {
				utils.getRecibo(route.params.recibo_id)
				.then( async data => {
					recibo.value = data
				}) 

				envios.value = await getEnvios()			
				
				if (route.name == "Admin_Recibo") {
					admin.value = true
				}								
			})	

			async function cobrar() {
				let loader = loading.show()

				try {
					const res = await axios.post(`/api/cobrar`, {
						recibo_id: recibo.value.id,
						proveedor_id:recibo.value.proveedor,
						payment_method: paymentMethod.value.id
					})	

					console.log(res)

					if (res.status == 200) {
						utils.updateRecibo(recibo.value.id, {pago: res.data.id})
						loader.hide()
						recibo.value.pago = res.data.id				
					} else {
						loader.hide()
						alert('Hubo un error, pregúntale a Noah')
					}						
				} catch(e) {
					loader.hide()
					alert('Hubo un error, pregúntale a Noah')
				}				
			}

			function marcarPagado() {
				if(confirm("¿Quieres marcar este recibo cómo pagado?")) {		
					let loader = loading.show()
					utils.updateRecibo(recibo.value.id, {pago: "pago manual"})
					.then( () => {
						recibo.value.pago = "pago manual"
						loader.hide()
					}) 
				}
			}

			async function uploadFactura(evt) {
				let loader = loading.show()

				uploadFile(evt)
				.then( fileName => {					
					return getURL(fileName)
				})
				.then( url => {										
					utils.updateRecibo(recibo.value.id, {factura: url})
					.then( () => {
						recibo.value.pago = "pago manual"
						loader.hide()
					}) 
				})	
				.catch( error => {
					console.log(error)
					loader.hide()
					alert("Hubo un error, favor de intentarlo otra vez.")
				})			
			}

			async function uploadFile(evt) {									
				if (!evt.target.files || evt.target.files.length === 0) {
					throw new Error("Selecciona un archivo")
				}
				const file = evt.target.files[0]
				const fileExt = file.name.split(".").pop()
				const fileName = `${Math.random()}.${fileExt}`
				const filePath = `${fileName}`					

				return await utils.uploadFile('foto', filePath, file)						
			}

			async function getURL(file) {	
				console.log(file)
				let fileOnly = file.split('foto/')[1]
				return await utils.getURL('foto', fileOnly)				
			}	

			return {
				route,
				envios,	
				recibo,
				router,
				utils,
				paymentMethod,
				paymentMethods,
				admin,
				cobrar,
				marcarPagado,
				uploadFactura
			}
		}
	}
</script>