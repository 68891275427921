<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">
			<h3 class="text-2xl font-bold pt-4 px-4 flex content-center">  
				<div v-if="route && !route.name.includes('Alta')" @click="utils.goBack(router, `/proveedor/${route.params.proveedor_id}/cliente/${cliente.id}`)" class="pr-2 flex items-center cursor-pointer">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
						<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
					</svg>
				</div>	
				<div>
					<div v-if="!proveedor" >      		
						Cliente
					</div>

					<div v-if="route && route.name.includes('Alta')">      		
						{{proveedor ? proveedor.nombre : ""}} - Alta de Cliente
					</div>

					<div v-if="route && !route.name.includes('Alta') && proveedor && proveedor.id" >      		
						{{ cliente.id ? "Editar cliente" : "Agregar cliente"}} 
					</div>

					<div v-if="proveedor && !proveedor.id">      		
						Cliente
					</div>

				</div>
			</h3>

			<div class="w-full p-4">								
				<div class="mb-4 w-full">
					<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
						Datos
					</label>
					<input class="appearance-none border border-gray-300 focus:border-gray-500 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full mb-2" id="nombre" type="text" placeholder="Nombre comercial" v-model="cliente.nombre">		
					<input class="appearance-none border border-gray-300 focus:border-gray-500 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full mb-2" id="nombre" type="text" placeholder="RFC" v-model="cliente.rfc">

					<div class="flex justify-center items-center">

						<a v-if="cliente.rfc_enlace" class="underline cursor-pointer whitespace-nowrap pr-4"  target="_blank" :href="cliente.rfc_enlace">
							Descargar RFC
						</a>

						<!-- && route.params.proveedor_id --> 
						<div v-if="route" class="cursor-pointer  text-center w-full bg-primary hover:bg-primary-dark rounded text-white font-bold">
							<label class="cursor-pointer  inline-block w-full py-2 px-4" for="single">{{cliente.rfc_enlace ? "Actualizar RFC" : "Subir RFC"}}</label>
							<input
							class="hidden"
							type="file"
							id="single"
							accept="application/pdf"
							@change="uploadRFC(pedido, $event)"
							/>
						</div>
					</div>
				</div>

				<div class="mb-4">
					<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
						Dirección de Entrega
					</label>
					<input class="appearance-none border border-gray-300 focus:border-gray-500 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2" id="nombre" type="text" placeholder="Exterior" v-model="cliente.direccion_exterior">
					<input class="appearance-none border border-gray-300 focus:border-gray-500 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2" id="nombre" type="text" placeholder="Calle" v-model="cliente.direccion_calle">
					<input class="appearance-none border border-gray-300 focus:border-gray-500 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2" id="nombre" type="text" placeholder="Interior/Local" v-model="cliente.direccion_interior">	
					<input class="appearance-none border border-gray-300 focus:border-gray-500 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2" id="nombre" type="text" placeholder="Colonia" v-model="cliente.direccion_colonia">	
					<input class="appearance-none border border-gray-300 focus:border-gray-500 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2" id="nombre" type="text" placeholder="Código postal" v-model="cliente.direccion_codigo">	

					<select v-model="cliente.direccion_estado" id="estados" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
						<option selected disabled value="">Escoger un estado</option>
						<option v-for="estado in estados" v-bind:key="estado" :value="estado">{{estado}}</option>			
					</select>

				</div>

				<div class="mb-4">
					<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
						Contacto
					</label>
					<input class="appearance-none border border-gray-300 focus:border-gray-500 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2" id="nombre" type="text" placeholder="Nombre" v-model="cliente.contacto_nombre">
					<input class="appearance-none border border-gray-300 focus:border-gray-500 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2" id="nombre" type="tel" placeholder="Teléfono" v-model="cliente.contacto_telefono">
					<input class="appearance-none border border-gray-300 focus:border-gray-500 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="nombre" type="email" placeholder="Correo" v-model="cliente.contacto_correo">	
				</div>	

				<div class="" v-if="proveedor && !proveedor.standalone">
					<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
						Horario de Entrega
					</label>			
					<div v-for="day in days" v-bind:key="day">					
						<div class="grid grid-cols-9 gap-2 flex items-center h-12">					
							<p class="col-span-2">{{day}}</p>
							<input id="default-checkbox" type="checkbox" v-model="cliente.horario[day].open" class="col-span-1 w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
						<!-- 	<input v-if="cliente.horario[day].open == true" class="appearance-none h-10 col-span-3 border border-gray-300 focus:border-gray-500 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline whitespace-nowrap" id="nombre" type="time" v-model="cliente.horario[day].start">					
							<input v-if="cliente.horario[day].open == true" class="appearance-none h-10 col-span-3 border border-gray-300 focus:border-gray-500 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline whitespace-nowrap" id="nombre" type="time" v-model="cliente.horario[day].end" > -->

							<select v-if="cliente.horario[day].open == true" v-model="cliente.horario[day].start" class="h-10 col-span-3 border border-gray-300 focus:border-gray-500 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline whitespace-nowrap">			
								<option selected disabled value="">Escoger horario</option>
								<option value="10:00">10:00 AM</option>			
								<option value="11:00">11:00 AM</option>			
								<option value="12:00">12:00 PM</option>			
								<option value="13:00">1:00 PM</option>			
								<option value="14:00">2:00 PM</option>			
								<option value="15:00">3:00 PM</option>			
								<option value="16:00">4:00 PM</option>											
								<option value="17:00">5:00 PM</option>											
								<option value="18:00">6:00 PM</option>											
								<option value="19:00">7:00 PM</option>											
								<option value="20:00">8:00 PM</option>											
							</select>

							<select v-if="cliente.horario[day].open == true" v-model="cliente.horario[day].end" class=" h-10 col-span-3 border border-gray-300 focus:border-gray-500 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline whitespace-nowrap">			
								<option selected disabled value="">Escoger horario</option>
								<option value="10:00">10:00 AM</option>			
								<option value="11:00">11:00 AM</option>			
								<option value="12:00">12:00 PM</option>			
								<option value="13:00">1:00 PM</option>			
								<option value="14:00">2:00 PM</option>			
								<option value="15:00">3:00 PM</option>			
								<option value="16:00">4:00 PM</option>	
								<option value="17:00">5:00 PM</option>											
								<option value="18:00">6:00 PM</option>											
								<option value="19:00">7:00 PM</option>											
								<option value="20:00">8:00 PM</option>												
							</select>						
						</div>					
					</div>							
				</div>		
			</div>
		</div>


		<div v-if="proveedor" class="p-4 flex-none border-t border-t-gray-300">
			<button v-if="proveedor.id" class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="execute">
				{{ cliente.id ? "Actualizar cliente" : "Crear perfil"}}
			</button>
		</div>
		
	</div>
</template>

<script>
	import { supabase } from "../supabase"
	import { useRoute, useRouter } from 'vue-router'
	import { onMounted, ref, computed } from "vue"
	import { useToast } from 'vue-toast-notification';
	import { useLoading } from 'vue-loading-overlay'
	import axios from "axios"
	import { utils } from "./utils/utils.js"

	const loading = useLoading({
		color: "#428b74",
		loader: "spinner"
	});

	export default {
		name: 'Order',
		setup() {
			const route = useRoute()
			const router = useRouter()						
			const proveedor = ref(null)
			const clienteProveedor = ref(null)
			const estados = ref(["Aguascalientes",
				"Baja California",
				"Baja California Sur",
				"Campeche",
				"Chiapas",
				"Chihuahua",
				"Ciudad de México",
				"Coahuila",
				"Colima",
				"Durango",
				"Estado de México",
				"Guanajuato",
				"Guerrero",
				"Hidalgo",
				"Jalisco",
				"Michoacán",
				"Morelos",
				"Nayarit",
				"Nuevo León",
				"Oaxaca",
				"Puebla",
				"Querétaro",
				"Quintana Roo",
				"San Luis Potosí",
				"Sinaloa",
				"Sonora",
				"Tabasco",
				"Tamaulipas",
				"Tlaxcala",
				"Veracruz",
				"Yucatán",
				"Zacatecas"])

			const cliente = ref({ 
				horario: {
					lunes: { open: true, start: "", end: "" },
					martes: { open: true, start: "", end: "" },
					miercoles: { open: true, start: "", end: "" },
					jueves: { open: true, start: "", end: "" },
					viernes: { open: true, start: "", end: "" },
					sabado: { open: false },
					domingo: { open: false }
				},
				direccion_estado: ""
			})

			const days = ref([
				"lunes",
				"martes",
				"miercoles",
				"jueves",
				"viernes",
				"sabado",
				"domingo",
				])

			async function getProveedor(proveedor_id) {
				let { data, error } = await supabase
				.from("proveedores")
				.select('id, nombre, funciones, standalone')          
				.eq("id", proveedor_id)
				.single()

				if (data) {   
					proveedor.value = data

					if (proveedor.value.standalone) {
						cliente.value.horario = {
							lunes: { open: false },
							martes: { open: false },
							miercoles: { open: false },
							jueves: { open: false },
							viernes: { open: false },
							sabado: { open: false },
							domingo: { open: false }
						}
					}

					return data
				} else if (error) {  
					alert(error)     
				}   

				return
			}
			
			async function mandarNuevoCliente(clienteProveedor) {
				try {
					axios
					.post('/api/mandar_nuevo_cliente', {
						cliente_proveedor_id: clienteProveedor.id
					})
					.finally( () => {
						return
					})
				}
				catch (e) {
					console.log(e)
				}
			}

			async function getClienteProveedor() {				
				let { data, error } = await supabase
				.from("cliente_proveedor")
				.select('*')          
				.eq("proveedor", route.params.proveedor_id)
				.eq("cliente", route.params.cliente_id)
				.single()

				if (data) {   
					console.log(data)
					clienteProveedor.value = data
					return data
				} else if (error) {  					
					alert(error)     
				}   

				return
			}

			async function updateClienteProveedor(clienteProveedor) {				
				const { error } = await supabase
				.from('cliente_proveedor')
				.update({ 					
					activo: clienteProveedor.activo,
					require_aprobacion: clienteProveedor.require_aprobacion				
				})
				.match({ id: clienteProveedor.id })

				if (error) {					
					alert(error)
				} else {
					useToast().success('Cliente actualizado', { position: "top-right", duration: 5000 });					
				}
			}   

			function hasRequiredValues() {
				let missingDays = Object.keys(cliente.value.horario).map( (key) => {
					console.log("cliente.value.horario[key]", cliente.value.horario[key].open)
					if (cliente.value.horario[key].open != false) {
						if (!cliente.value.horario[key].start || !cliente.value.horario[key].end) {
							return 1
						} 

						return 0
					}

					return 0
				}).reduce((a, b) => a + b)

				console.log("missingDays", missingDays)

				if (missingDays > 0) {
					useToast().warning('Asegúrate que el horario está completo', { position: "top-right" });
					return false
				}

				// let total = productos.value.map ( p => { return p.cantidad || 0}).reduce((a, b) => a + b)         

				let keys = [				
					"nombre",
					"direccion_exterior",
					"direccion_calle",
					"direccion_colonia",
					"direccion_codigo",
					"direccion_estado",
					"contacto_nombre",
					"contacto_telefono",				
					"horario"
					]

				let values = 0

				keys.forEach( key => {
					console.log(key, cliente.value[key])
					if (cliente.value[key]) {						
						values++			
					}
				})								

				if (values != keys.length) {
					useToast().warning('Asegúrate que todos los datos están', { position: "top-right" });
					return false
				} else {
					return true
				}
			}

			async function execute() {				
				if (!hasRequiredValues()) {					
					return 
				}

				var loader = loading.show()		

				if (route && route.name.includes('Alta')) {
					upsertCliente()	
					.then( (cliente) => {
						return createClienteProveedor(cliente)
					})
					.then( (clienteProveedor) => {
						loader.hide()		
						mandarNuevoCliente(clienteProveedor)
						useToast().success('¡Perfil creado!', { position: "top-right" });
						console.log(clienteProveedor)
						router.push(`/cliente/${clienteProveedor.cliente}`)
					}) 
					.catch( (error) => {						
						loader.hide()		
						alert(error)	
					})
				} else if (cliente.value.id) {
					upsertCliente()	
					.then( () => {
						loader.hide()		
						useToast().success('Cliente actualizado', { position: "top-right" });
						// router.push(`/proveedor/${proveedor.value.id}/cliente/${cliente.value.id}`)
					}) 
					.catch( (error) => {						
						loader.hide()		
						alert(error)	
					})
				} else {
					upsertCliente()
					.then( (cliente) => {
						return createClienteProveedor(cliente)
					})
					.then( () => {
						loader.hide()	
						useToast().success('Cliente creado', { position: "top-right" });
						router.push(`/proveedor/${proveedor.value.id}/clientes/`)
					}) 
					.catch( (error) => {						
						loader.hide()		
						alert(error)	
					})
				}				
			}

			async function createClienteProveedor(cliente) {
				let { data, error } = await supabase
				.from('cliente_proveedor')
				.insert([{
					"cliente": cliente.id,
					"proveedor": proveedor.value.id
				}])				

				if (data) {
					console.log(data)           									
					return (data[0])
				} else if (error) {  					
					throw error     
				}     
			}

			async function upsertCliente() {
				

				let clienteParaUpsert = {
					"nombre": cliente.value.nombre,
					"rfc": cliente.value.rfc,
					"rfc_enlace": cliente.value.rfc_enlace,
					"direccion_exterior": cliente.value.direccion_exterior,
					"direccion_interior": cliente.value.direccion_interior,
					"direccion_calle": cliente.value.direccion_calle,
					"direccion_colonia": cliente.value.direccion_colonia,
					"direccion_codigo": cliente.value.direccion_codigo,
					"contacto_nombre": cliente.value.contacto_nombre,
					"contacto_telefono": cliente.value.contacto_telefono,
					"contacto_correo": cliente.value.contacto_correo,
					"horario": cliente.value.horario				
				}

				if (cliente.value.direccion_estado) {
					clienteParaUpsert.direccion_estado = cliente.value.direccion_estado
				}

				if (cliente.value.id) {
					clienteParaUpsert.id = cliente.value.id
				} else {
					clienteParaUpsert.id = await utils.getUniqueID('clientes')
				}

				let { data, error } = await supabase
				.from('clientes')
				.upsert([clienteParaUpsert])										

				if (data && data[0]) {         									
					return data[0]
				} else if (error) { 			
					throw error
				}     
			}	

			async function getCliente() {
				let { data, error, status } = await supabase
				.from("clientes")
				.select('*')          
				.eq("id", route.params.cliente_id)
				.single()

				if (error && status !== 406) {
					throw error
				}

				if (data) {             
					if (!data.horario) {
						data.horario = {
							lunes: {},
							martes: {},
							miercoles: {},
							jueves: {},
							viernes: {},
							sabado: {},
							domingo: {}
						}
					}
					cliente.value = data
				} else if (error) { 
					alert(error)     
				}   
			}   

			const uploadRFC= async (pedido, evt) => {		
				var loader = loading.show()						
				
				uploadFile(evt)
				.then( fileName => {					
					return getURL(fileName)
				})
				.then( url => {					
					cliente.value.rfc_enlace = url
					useToast().success('RFC subido', { position: "top-right" });

					if (cliente.value.id) {
						upsertCliente()	
						.then( () => {
							loader.hide()		
							useToast().success('Cliente actualizado', { position: "top-right" });							
						}) 
						.catch( (error) => {						
							loader.hide()		
							alert(error)	
						})
					} else {
						loader.hide()		
					}
				})				
			}

			async function uploadFile(evt) {							
				if (!evt.target.files || evt.target.files.length === 0) {
					throw new Error("Selecciona un archivo")
				}
				const file = evt.target.files[0]
				const fileExt = file.name.split(".").pop()
				const fileName = `${Math.random()}.${fileExt}`
				const filePath = `${fileName}`					

				let { data, error } = await supabase.storage
				.from("rfc")
				.upload(filePath, file)					
				
				if (data && data.Key) {           
					return data.Key				
				} else if (error) {  
					throw error
				} else {
					throw "Hubo un error, favor de intentarlo otra vez."
				} 	
			}

			async function getURL(file) {	
				let fileOnly = file.split('rfc/')[1]
				
				const { data, error } = supabase
				.storage
				.from('rfc')
				.getPublicUrl(fileOnly)

				if (data && data.publicURL) {           
					return data.publicURL				
				} else if (error) {  
					throw error
				} else {
					throw "Hubo un error, favor de intentarlo otra vez."
				} 
			}	

			onMounted(async () => {								
				if (route.params.proveedor_id) {
					getProveedor(route.params.proveedor_id)
				}

				if (route.query.proveedor_id) {
					getProveedor(route.query.proveedor_id)
				}

				if (route.params.cliente_id) {
					getCliente()					
				}

				if (route.params.cliente_id && route.params.proveedor_id) {
					getClienteProveedor()
				}

			})

			const showAprobar = computed( () => {
				return proveedor.value != null && proveedor.value.funciones &&  proveedor.value.funciones.includes('aprobar')
			})

			return {      
				route,	
				router,			
				proveedor,
				cliente,
				execute,
				days,
				uploadRFC,
				updateClienteProveedor,
				clienteProveedor,
				showAprobar,
				estados,
				mandarNuevoCliente,
				utils
			}
		},
	}
</script> 