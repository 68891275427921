<template>		

	<div class="p-8 bg-white rounded w-96">	
		<div class="flex justify-between mb-4">
			<div class="text-2xl"> Pago </div>				
			<svg @click="handler(false)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="cursor-pointer w-6 h-6">
				<path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
			</svg>
		</div>

		<div class="flex justify-between mb-4">
			<div> 
				Total:
			</div>
			<div>
				${{pedido.line_items.map( l => {return l.precio * l.cantidad} ).reduce((a, b) => a + b)}}
			</div>
		</div>

		<div v-if="paymentMethods && paymentMethods.length > 0" class="w-full text-left mb-4">
			<label class="block text-gray-700 text-sm font-bold mb-2 mt-0" >
				Método de pago
			</label>
			<select @change='createElements' v-model="paymentMethod" id="" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">					
				<option v-for="paymentMethod in paymentMethods" v-bind:key="paymentMethod.id" :value="paymentMethod">{{capitalizeFirstLetter(paymentMethod.card.brand)}} {{paymentMethod.card.last4}}</option>
				<option value="new">Nueva tarjeta</option>
			</select>
		</div>

		<div  v-if="paymentMethod != 'new'">
			<div @click="processPayment()" class="cursor-pointer h-11 w-full text-center bg-primary mt-4 rounded-md justify-center flex flex-col content-center">
				<button id="submit-payment-btn" class="text-white text-vc-small">
					Pagar
				</button>
			</div>
		</div>

		<div  v-if="paymentMethod == 'new'">
			<form id="payment-form" class="">
				<div class="group">
					<label>
						<span>Número de tarjeta</span>						
						<div id="card-number-element" class="mt-1 px-3 py-0.5 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 h-11"></div>
					</label>

					<div class="grid grid-cols-2 gap-4 mt-2">
						<div>
							<label>
								<span>Fecha de vencimiento</span>
								<div id="card-expiry-element" class="mt-1 px-3 py-0.5 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 h-11"></div>
							</label>
						</div>
						<div class="">
							<label class="flex justify-between h-full flex-col">
								<span>CVC</span>
								<div id="card-cvc-element"  class="mt-1 px-3 py-0.5 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 h-11"></div>
							</label>
						</div>

					</div>			
				</div>

				<div id="card-errors" role="alert" class="mt-4 text-center text-primary h-6"></div>
				<div class="cursor-pointer h-11 w-full text-center bg-primary mt-4 rounded-md justify-center flex flex-col content-center">
					<button id="submit-payment-btn" class="text-white text-vc-small">
						Pagar
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import { onMounted, ref } from "vue"
	import axios from "axios"

	import { useLoading } from 'vue-loading-overlay'
	import { utils } from '../utils/utils.js'
	import { useToast } from 'vue-toast-notification'

	export default {
		props: ['handler', 'pedido', 'paymentMethods'],

		setup(props) {		
			const loading = useLoading({
				color: "#428b74",
				loader: "spinner"
			});

			const paymentMethod = ref("new")

			// let stripe = window.Stripe('pk_test_WaZMhzPcsupO8bPJSSvmRKS400cTOJbtmI', {
			// 	locale: 'es-419'
			// });

				let stripe = window.Stripe('pk_live_Zi8ozD62RnknTUOwVetkTPrf003KawSnHs', {
					locale: 'es-419'
				});

			onMounted( () => {				
				if (props.paymentMethods.length > 0) {
					paymentMethod.value = props.paymentMethods[0]
				}

				createElements()								
			})	

			function showError(event) {
				var displayError = document.getElementById('card-errors');
				if (event.error) {
					displayError.textContent = event.error.message;
				} else {
					displayError.textContent = '';
				}
			}

			function paymentIsValid() {
				let elements = ['card-number-element', 'card-expiry-element', 'card-cvc-element']

				let isInvalid = elements.map( element => {				
					const elementToValidate = document.getElementById(element);					
					return elementToValidate.classList.contains('StripeElement--empty')			
				}).filter( e => e == true)

				if (isInvalid.length > 0) {
					return false
				}

				return true
			}

			async function updatePedidoWithoutConfirm(data) {								
				utils.updatePedido(props.pedido.id, data)
				.then( () => {								
					useToast().success('¡Gracias por tu pago!', { position: "top-right", duration: 5000 });									
				})
			}

			function capitalizeFirstLetter(string) {
				return string.charAt(0).toUpperCase() + string.slice(1);
			}

			async function getSecret() {
				// ?pedido_id=${props.pedido.id}&cliente_id=${props.pedido.cliente.id}
				let paymentData = {
					pedido_id: props.pedido.id,
					cliente_id: props.pedido.cliente.id
				}

				if (paymentMethod.value != 'new') {
					paymentData.payment_method = paymentMethod.value.id
				}

				return axios.get(`/api/get_secret`, { params: paymentData })
				.then((data) => {    
					return (data)
				})
				.catch((error) => {
					console.log(error)
				});  
			}

			function cancel() {
				props.handler(false)
			}

			function createElements() {	
				if (paymentMethod.value != "new") {
					return 
				}
				let elements = stripe.elements({fonts: [{
					cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap',
				}]});			

				var style = {
					base: {
						iconColor: '#666EE8',
						color: '#31325F',
						lineHeight: '40px',
						fontWeight: 300,
						fontFamily: 'Poppins',
						fontSize: '16px',
						'::placeholder': {
							color: '#b2b2b9',
						},
					},
				};

				var cardNumberElement = elements.create('cardNumber', {
					style: style,
					placeholder: 'Número de tarjeta',
					showIcon: true
				});
				cardNumberElement.mount('#card-number-element');

				var cardExpiryElement = elements.create('cardExpiry', {
					style: style,
					placeholder: 'MM/AA',
				});
				cardExpiryElement.mount('#card-expiry-element');

				var cardCvcElement = elements.create('cardCvc', {
					style: style,
					placeholder: 'CVC',
				});
				cardCvcElement.mount('#card-cvc-element');

				cardNumberElement.on('change', function(event) {
					showError(event);
				});

				cardExpiryElement.on('change', function(event) {
					showError(event);
				});

				cardCvcElement.on('change', function(event) {
					showError(event);
				});

				const btn = document.querySelector('#submit-payment-btn');
				
				btn.addEventListener('click', async (e) => {
					console.log('CLICK')
					e.preventDefault();			

					processPayment(cardNumberElement)
				})
			}

			async function processPayment(cardNumberElement) {
				if (cardNumberElement && !paymentIsValid()) {
					return alert('¡Faltan campos por llenar!')
				}

				let loader = loading.show()

				let response = await getSecret()			

				console.log('response', response)	

				if (!response || !response.data || !response.data.clientSecret) {
					loader.hide()
					alert('Hubo un error, por favor de intenta otra vez')
					return
				}

				let paymentData = {} 

				if (cardNumberElement) {
					paymentData = {
						payment_method: {
							card: cardNumberElement		
						}
					}
				} else {
					paymentData = {
						payment_method: paymentMethod.value.id
					}
				}

				stripe.confirmCardPayment(response.data.clientSecret, paymentData).then((result) => {
					if(result.error) {
						loader.hide()
						throw(result.error.message);
					} else {										
						console.log("SUCCESS")
						return updatePedidoWithoutConfirm({ pagado: true})						
					}
				}).then( () => {
					loader.hide()
					props.handler(true)
				})
				.catch( e => {
					console.log('ERROR')
					alert(e)
					loader.hide()
						// props.handler(false)
				})		
			}

			return {
				props,
				loading,
				cancel,
				paymentMethod,
				capitalizeFirstLetter,
				createElements,
				processPayment
			}
		}
	}
</script>

